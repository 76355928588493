/* eslint-disable camelcase */

export type ShippingMethod = 'shipping' | 'pick_up'

export enum OrderStatus {
  pending = 'pending',
  approved = 'approved',
  in_line = 'in_line',
  in_progress = 'in_progress',
  updating_fiscal_data = 'updating_fiscal_data',
  ready_for_pick_up = 'ready_for_pick_up',
  shipping = 'shipping',
  delivered = 'delivered',
  canceled = 'canceled'
}

export type OrderHardwareStatus =
  | 'ordered'
  | 'canceled'
  | 'returned'
  | 'ownership_changed'

export enum OrderConnectivityStatus {
  canceled = 'canceled',
  returned = 'returned',
  ordered = 'ordered',
  suspended = 'suspended'
}

export interface Installment {
  type: string
  amount: number | string
  due_at: Date
  upfront: boolean
  eligible: boolean
}

export interface Order {
  id: number
  client_id: number
  plan_id: number
  token: string
  batch: string
  quantity: number
  contract_id?: number
  amount: number | string
  shipping_method: ShippingMethod
  ordered_at?: Date | string
  signed_at?: Date
  activated_at?: Date
  approved_at?: Date
  queued_at?: Date
  processing_at?: Date
  fiscal_data_updated_at?: Date
  ready_at?: Date
  shipped_at?: Date
  canceled_at?: Date
  current_status: string
  end_at?: Date
  created_at: Date
  updated_at: Date
  deleted_at?: Date
  minimum_invoice_amount: number | string
  fine_mode: string | null
  fine_amount: number | string | null
  relationships?: Record<string, any>
  coupon_id?: number
  setup_fee: number | string
}
