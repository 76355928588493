import { toast } from 'react-toastify'
import { Link, useHistory } from 'react-router-dom'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { EyeIcon, PencilIcon } from '@heroicons/react/outline'

import { PageHeader } from 'components/PageHeader'
import { LoadingCircle } from 'components/Loading'
import { Pagination } from 'components/Pagination'
import { SimpleButton } from 'components/FormInputs/Button'
import { ProtectedSection } from 'components/ProtectedSection'
import { ToggleActivation } from '../components/ToggleActivation'

import config from 'config'
import { list } from '../services/read'
import { amount, currency, date, time } from 'utils'
import { translations } from '../translations'
import { translations as countryTranslations } from 'modules/Clients/translations'
import { useURLParams } from 'hooks/useURLParams'
import { ReadResult } from 'interfaces/queryOptions'
import { Plan, UsageType, Role } from 'models'
import { SearchBar } from 'components/Search'
import { SortDownIcon } from 'components/Table/SortDownIcon'
import { SorUptIcon } from 'components/Table/SortUpIcon'
import { SortIcon } from 'components/Table/SortIcon'

export function List() {
  const { replace } = useHistory()

  const query = useURLParams()
  const queryParams = useMemo(() => {
    const params = {
      search: ''
    }

    const search = query.get('search')

    if (search) params.search = search

    return params
  }, [query])

  const [page, setPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)

  const [sort, setSort] = useState<Record<string, number>>({ created_at: -1 })
  const sortableValues = ['usage_type', 'created_at']
  const [sortedValue, setSortedValue] = useState<string>('created_at')
  const [isSortedDesc, setIsSortedDesc] = useState(false)

  const [search, setSearch] = useState(queryParams.search)

  const [total, setTotal] = useState(0)
  const [data, setData] = useState<ReadResult<Plan>[]>([])

  const [isLoading, setIsLoading] = useState(true)

  const maxItemsPerPage = config.items_per_list_page

  useEffect(() => {
    async function loadData() {
      setIsLoading(true)

      const options = {
        limit: maxItemsPerPage,
        search,
        page,
        sort,
        includes: { country: ['name'] },
        filters: [{ key: 'usage_type', op: 'neq', value: 'trial' }]
      }

      try {
        const { count, data } = await list(options)
        setData(data)
        setTotal(count)
        setIsLoading(false)
      } catch (err) {
        setIsLoading(false)
        toast.error('Falha ao buscar acessos')
        console.error(err)
      }
    }
    loadData()
  }, [maxItemsPerPage, page, search, sort])

  useEffect(() => {
    const newLastPage = Math.ceil(total / maxItemsPerPage)
    setLastPage(newLastPage)
  }, [maxItemsPerPage, total])

  const onSort = useCallback(
    (sortBy: string) => {
      if (!sortableValues.includes(sortBy)) return
      const sortValue = isSortedDesc ? -1 : 1
      setSort({ [sortBy]: sortValue })
      setSortedValue(sortBy)
      setIsSortedDesc(!isSortedDesc)
    },
    [isSortedDesc]
  )

  const onPageChange = useCallback(
    (page: number) => {
      const newPage = Math.min(Math.max(1, page), lastPage)
      setPage(newPage)
    },
    [lastPage]
  )

  const onToggle = useCallback(
    (id: number, isActive: boolean) => {
      setData(
        data.map((plan: any) => {
          if (plan.id === id) {
            return {
              ...plan,
              attributes: {
                ...plan.attributes,
                active: isActive
              }
            }
          }
          return plan
        })
      )
    },
    [data]
  )

  const onSearch = useCallback(
    (term: string) => {
      if (!term || !term.length || term.length < config.min_search_length) {
        term = ''
      }

      if (term === '') {
        query.delete('search')
      } else {
        query.set('search', term)
      }

      replace({
        pathname: window.location.pathname,
        search: query.toString()
      })

      setSearch(term)
    },
    [query, replace]
  )

  return (
    <>
      <PageHeader title="Planos de acesso" action="Listagem">
        <SearchBar value={search} onChange={onSearch} />
        <ProtectedSection roles={[]}>
          <Link to="/access-plans/create">
            <SimpleButton>Novo</SimpleButton>
          </Link>
        </ProtectedSection>
      </PageHeader>

      {isLoading ? (
        <LoadingCircle />
      ) : (
        <div className="flex flex-col animate-fade-in-down">
          <div className="-my-2 overflow-x-auto px-4">
            <div className="py-2 align-middle inline-block min-w-full">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-4 py-3 text-left text-sm font-light text-gray-500"
                      >
                        ATIVO
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3 text-left text-sm font-light text-gray-500"
                      >
                        NOME
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3 text-left text-sm font-light text-gray-500"
                      >
                        PAÍS
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3 text-left text-sm font-light text-gray-500 group cursor-pointer"
                        onClick={() => onSort('usage_type')}
                      >
                        <div className={'flex justify-between items-center'}>
                          <div>TIPO</div>
                          {sortedValue === 'usage_type' ? (
                            isSortedDesc ? (
                              <SortDownIcon className="h-4 w-4" />
                            ) : (
                              <SorUptIcon className="h-4 w-4" />
                            )
                          ) : (
                            <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                          )}
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3 text-left text-sm font-light text-gray-500"
                      >
                        VALOR
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3 text-left text-sm font-light text-gray-500"
                      >
                        DURAÇÃO
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3 text-left text-sm font-light text-gray-500"
                      >
                        COBRANÇA
                      </th>
                      <th
                        scope="col"
                        className="group cursor-pointer px-4 py-3 text-left text-sm font-light text-gray-500 group cursor-pointer"
                        onClick={() => onSort('created_at')}
                      >
                        <div className={'flex justify-between items-center'}>
                          <div>CRIADO EM</div>
                          {sortedValue === 'created_at' ? (
                            isSortedDesc ? (
                              <SortDownIcon className="h-4 w-4" />
                            ) : (
                              <SorUptIcon className="h-4 w-4" />
                            )
                          ) : (
                            <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                          )}
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3 text-left text-sm font-light text-gray-500"
                      >
                        AÇÕES
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {data.map((plan, index) => (
                      <tr
                        key={plan.id}
                        className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                      >
                        <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                          {plan.attributes.active ? (
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-emerald-100 text-emerald-800">
                              Sim
                            </span>
                          ) : (
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                              Não
                            </span>
                          )}
                        </td>
                        <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                          {plan.attributes.name ?? '-'}
                        </td>
                        <td className="px-4 py-3 text-left text-sm max-w-xs truncate whitespace-nowrap">
                          {
                            countryTranslations.country[
                              plan.relationships?.country.attributes
                                .name as string
                            ]
                          }
                        </td>
                        <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                          <div className="flex">
                            <div className="flex-1">
                              <div className="text-xs text-left font-semibold text-gray-900">
                                {plan.attributes.usage_type === 'tiered'
                                  ? `${
                                      translations.type[
                                        plan.attributes.usage_type as string
                                      ]
                                    }: ${
                                      translations.tier_mode[
                                        plan.attributes.tier_mode as string
                                      ]
                                    }`
                                  : translations.type[
                                      plan.attributes.usage_type as string
                                    ] ?? '-'}
                              </div>
                              <div className="text-xs text-left text-gray-500">
                                {
                                  translations.billing_type[
                                    plan.attributes.billing_type as string
                                  ]
                                }
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                          <div className="text-xs text-left text-gray-900">
                            {plan?.attributes.usage_type ===
                              UsageType.licensed ||
                            plan?.attributes.usage_type === UsageType.metered
                              ? `${currency(plan.attributes.currency)} ${amount(
                                  plan.attributes.amount
                                )}`
                              : '-'}
                          </div>
                        </td>
                        <td className="px-4 py-3 max-w-xs whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="flex-1 truncate">
                              <div className="text-xs text-left text-gray-900 truncate">
                                {' '}
                                {plan.attributes.recurring
                                  ? plan.attributes.duration + ' meses'
                                  : 'Não recorrente'}{' '}
                              </div>
                              {/* <div className="text-xs text-left text-gray-500"> { "-" } </div> */}
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                          <div className="flex">
                            <div className="flex-1">
                              <div className="text-xs text-left font-semibold text-gray-900">
                                {plan.attributes.recurring
                                  ? translations.interval[
                                      plan.attributes.interval as string
                                    ]
                                  : '-'}
                              </div>
                              <div className="text-xs text-left text-gray-500">
                                {plan.attributes.recurring
                                  ? plan.attributes.interval_count
                                  : '-'}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                          <div className="flex">
                            <div className="flex-1">
                              <div className="text-xs text-left font-semibold text-gray-900">
                                {date(plan.attributes.created_at)}
                              </div>
                              <div className="text-xs text-left text-gray-500">
                                {time(plan.attributes.created_at)}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-3 text-xs text-left text-gray-500 max-w-sm">
                          <div className="flex flex-row">
                            <Link to={`/access-plans/${plan.id}/view/general`}>
                              <button
                                type="button"
                                className="-ml-px relative inline-flex items-center px-3 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500"
                              >
                                <EyeIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </button>
                            </Link>
                            <ProtectedSection
                              roles={[Role.MANAGER, Role.FINANCE]}
                            >
                              <ToggleActivation
                                planId={plan.id}
                                isActive={plan.attributes.active ?? false}
                                onToggle={onToggle}
                              />
                              {!plan.attributes.active && (
                                <Link to={`/access-plans/${plan.id}`}>
                                  <button
                                    type="button"
                                    className="-ml-px relative inline-flex items-center px-3 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500"
                                  >
                                    <PencilIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </Link>
                              )}
                            </ProtectedSection>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="mx-4">
            <Pagination
              currentPage={page}
              pageSize={maxItemsPerPage}
              itemsOnPage={data.length}
              totalItems={total}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      )}
    </>
  )
}
