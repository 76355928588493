import { Plan, PlanType, Role } from 'models'
import {
  list as baseList,
  show as baseShow,
  create as baseCreate,
  edit as baseEdit,
  destroy as baseDestroy,
  patch
} from 'services/billing'
import { ListResult, QueryOptions, ReadResult } from 'interfaces/queryOptions'
import { checkRoles, isNil, omitBy } from 'utils'

type PlanWithOptionalInstallments = {
  max_installments?: number
}

type PartialPlan = Partial<Plan> & PlanWithOptionalInstallments

export async function list(
  options: QueryOptions = {}
): Promise<ListResult<Plan>> {
  const response = await baseList<Plan>('/plans', options)
  return response
}

export async function show(
  id: number | string,
  options?: Pick<QueryOptions, 'attributes' | 'includes'>
): Promise<Partial<Plan>> {
  const response = await baseShow<Plan>('/plans', id, options)

  return {
    ...response.attributes,
    id: response.id,
    relationships: response.relationships
  }
}

export async function create(
  attributes: PartialPlan,
  meta: string,
  countryId: string
): Promise<ReadResult<Plan>> {
  const metaObj: any = {
    restriction: {}
  }

  if (attributes.max_installments) {
    metaObj.installments = [
      {
        type:
          attributes.type === PlanType.hardware ? 'hardware' : 'connectivity',
        requirements: {
          max_installment_number: Number(attributes.max_installments)
        }
      }
    ]

    delete attributes.max_installments
  }

  if (attributes.type === PlanType.hardware) {
    metaObj.restriction.device_types = {
      name: meta
    }
  } else {
    metaObj.restriction.chips = {
      service_provider: meta
    }
  }

  attributes.meta = metaObj

  if (attributes.fine_amount === 0) {
    attributes.fine_mode = 'fixed'
  }

  const data = {
    attributes: {
      ...omitBy(attributes, isNil)
    },
    relationships: {
      country: {
        type: 'country',
        id: countryId
      }
    }
  }

  const response = await baseCreate<Plan>('/plans', data)
  return response
}

export async function edit(
  id: number,
  attributes: PartialPlan,
  meta: string
): Promise<ReadResult<Plan>> {
  const metaObj: any = {
    restriction: {}
  }

  if (attributes.max_installments) {
    metaObj.installments = [
      {
        type:
          attributes.type === PlanType.hardware ? 'hardware' : 'connectivity',
        requirements: {
          max_installment_number: Number(attributes.max_installments)
        }
      }
    ]

    delete attributes.max_installments
  }

  if (attributes.type === PlanType.hardware) {
    metaObj.restriction.device_types = {
      name: meta
    }
  } else {
    metaObj.restriction.chips = {
      service_provider: meta
    }
  }

  attributes.meta = metaObj

  attributes.id = undefined
  attributes.created_at = undefined
  attributes.updated_at = undefined
  attributes.relationships = undefined

  if (attributes.fine_amount === 0) {
    attributes.fine_mode = 'fixed'
  }

  const { fine_mode, fine_amount, ...rest } = attributes

  const data = {
    attributes: {
      ...omitBy(rest, isNil),
      fine_mode,
      fine_amount
    }
  }

  const response = await baseEdit<Plan>('/plans', id, data)
  return response
}

export async function destroy(id: number | string): Promise<void> {
  await checkRoles([Role.MANAGER, Role.FINANCE])
  await baseDestroy('/plans', id)
}

export async function toggle(id: number): Promise<ReadResult<Plan>> {
  await checkRoles([Role.MANAGER, Role.FINANCE])
  const response = await patch(`/plans/${id}/toggle`)
  return response.data
}
