import { ReadResult } from 'interfaces/queryOptions'
import { Client, Installment, Order, Plan, Role, UsageType } from 'models'
import { Coupon } from 'models/coupon'
import { isCurrentMonth } from 'modules/Orders/helpers/utils'
import { newShow } from 'modules/Orders/services'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Errors } from 'types'
import {
  amount as formatAmount,
  currency,
  formatCurrency,
  cleanAmount
} from 'utils'
import { check } from 'utils/error'
import { edit } from '../services/update'
import { validateUpdate as validate } from 'modules/Orders/validation'
import { PageHeader } from 'components/PageHeader'
import { LoadingCircle } from 'components/Loading'
import { FormSection } from 'components/Form'
import { ClientSearch, PlanSearch } from 'modules/Orders/components/Searchs'
import { CouponSearch } from 'modules/Orders/components/Searchs/Coupon'
import { DatePickerWithError } from 'components/FormInputs/DatePicker'
import { getFirstDayOfMonth } from 'utils/datetime'
import {
  InputWithAddon,
  InputWithValidationError,
  SimpleCustomSelect
} from 'components/FormInputs'
import { fineModes } from 'components/FormInputs/options'
import { ProtectedSection } from 'components/ProtectedSection'
import { DestroyButton } from 'modules/Orders/components/Destroy'
import { Button, Close } from 'components/Buttons'

export function Edit() {
  const { subscriptionId } = useParams() as unknown as {
    subscriptionId: number
  }

  const history = useHistory()

  const [subscription, setSubscription] = useState<ReadResult<Order>>()
  const [client, setClient] = useState<ReadResult<Client>>()
  const [accessPlan, setAccessPlan] = useState<ReadResult<Plan>>()

  const [coupon, setCoupon] = useState<ReadResult<Coupon>>()

  const [shouldClear, setShouldClear] = useState(false)

  const [orderedAt, setOrderedAt] = useState(new Date())
  const [amount, setAmount] = useState('0,00')

  const [installments, setInstallments] = useState<Installment[]>([])

  const [errors, setErrors] = useState<Errors>({})

  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetch = async () => {
      if (!subscriptionId) return

      setIsLoading(true)
      try {
        const options = {
          includes: {
            client: ['id', 'account_name', 'document', 'document_type'],
            plan: [
              'id',
              'name',
              'type',
              'nature',
              'amount',
              'currency',
              'duration',
              'interval',
              'restricted',
              'country_id',
              'usage_type',
              'setup_fee'
            ],
            coupon: ['*'],
            order_installments: ['*']
          }
        }
        const result = await newShow(subscriptionId, options)

        if (result.relationships?.order_installments) {
          setInstallments(
            result.relationships.order_installments.map(
              ({ attributes }: any) => ({
                type: attributes.type,
                amount: attributes.amount,
                due_at: attributes.due_at,
                upfront: attributes.upfront,
                eligible: attributes.eligible
              })
            )
          )
        }

        setSubscription({
          ...result,
          attributes: {
            ...result.attributes,
            minimum_invoice_amount: formatAmount(
              result.attributes.minimum_invoice_amount
            ),
            fine_amount: formatAmount(result.attributes.fine_amount as number),
            setup_fee: formatAmount(result.attributes.setup_fee)
          }
        })

        setAmount(formatAmount(result.attributes.amount))
        setOrderedAt(new Date(result.attributes.ordered_at ?? new Date()))
        setClient(result.relationships?.client)
        setAccessPlan({
          ...result.relationships?.plan,
          relationships: {
            country: { id: result.relationships?.plan.attributes.country_id }
          }
        })

        setCoupon(result.relationships?.coupon ?? null)

        setIsLoading(false)
      } catch (err: any) {
        console.error(err)
        history.push('/susbcriptions')
        toast.error(
          err.suggestedMessage ?? 'Falha ao buscar os dados da assinatura'
        )
        setIsLoading(false)
      }
    }
    fetch()
  }, [subscriptionId])

  const onSave = useCallback(async () => {
    if (!subscription || !accessPlan || !client) return

    if (check(errors)) return

    subscription.attributes.ordered_at = isCurrentMonth(orderedAt)
      ? orderedAt
      : undefined
    subscription.attributes.amount = Math.round(+amount.replace(',', '.') * 100)
    if (!subscription.attributes.fine_amount) {
      subscription.attributes.fine_amount = 0
    }
    if (accessPlan.attributes.usage_type !== UsageType.licensed) {
      subscription.attributes.quantity = 1
    }

    setIsSaving(true)
    try {
      await edit(subscriptionId, { subscription, client, accessPlan, coupon })
      toast.success('Assinatura editada com sucesso')
      setIsSaving(false)
    } catch (err: any) {
      setIsSaving(false)
      toast.error(
        err.suggestedMessage ?? 'Houve uma falha ao salvar as alterações'
      )
    }
  }, [
    client,
    errors,
    subscriptionId,
    subscription,
    orderedAt,
    accessPlan,
    coupon,
    amount
  ])

  const onAttributeChange = useCallback(
    (attr: keyof Order, value: any): void => {
      if (!subscription) return

      const error = validate(attr as any, value)

      if (attr === 'fine_amount' && value === '') {
        setSubscription({
          ...subscription,
          attributes: {
            ...subscription.attributes,
            fine_amount: '',
            fine_mode: null as unknown as string
          }
        })
        return
      }

      if (
        attr === 'fine_amount' ||
        attr === 'minimum_invoice_amount' ||
        attr === 'setup_fee'
      ) {
        value = formatCurrency(value)
      }

      setSubscription({
        ...subscription,
        attributes: { ...subscription.attributes, [attr]: value }
      })
      setErrors({ ...errors, [attr]: error })
    },
    [errors, subscription]
  )

  const onAmountChange = useCallback((value: string) => {
    if (value === '-0,00' || value === '-0,0') {
      setAmount('0,00')
    } else {
      const newAmount = formatAmount(value.replace(/[^0-9]/g, ''))
      value.includes('-') ? setAmount('-' + newAmount) : setAmount(newAmount)
    }
  }, [])

  const onOrderedAtChange = useCallback((value: string) => {
    setOrderedAt(new Date(value))
  }, [])

  const onCouponSelection = useCallback((coupon: any): void => {
    setCoupon(coupon)
  }, [])

  return (
    <>
      <PageHeader title="Assinaturas" action="Edição" />

      {isLoading ? (
        <LoadingCircle />
      ) : (
        <div className="w-3/5 px-4 animate-fade-in-down">
          <h3 className="text-xl mb-5 leading-6 font-medium text-gray-900">
            Assinatura: {subscription?.attributes.token}
          </h3>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              onSave()
            }}
          >
            <FormSection title="Cliente">
              <ClientSearch
                value={client}
                onSelect={setClient}
                error={errors?.client_id}
              />
            </FormSection>

            <FormSection title="Acesso">
              <PlanSearch
                clear={false}
                value={accessPlan}
                onSelect={setAccessPlan}
                onChange={setAccessPlan}
                error={errors?.plan_id}
                subscription={true}
              />
            </FormSection>

            <FormSection title="Assinatura">
              {accessPlan &&
                accessPlan.attributes.usage_type === UsageType.licensed && (
                  <div className="flex justify-between gap-8">
                    <div className="w-1/2">
                      <InputWithValidationError
                        label="Quantidade"
                        type="number"
                        name="quantity"
                        min="1"
                        value={subscription?.attributes.quantity}
                        onChange={(value) =>
                          onAttributeChange('quantity', value)
                        }
                        error={errors?.quantity}
                        onWheel={(e: React.FocusEvent<HTMLInputElement>) =>
                          e.target.blur()
                        }
                      />
                    </div>

                    <div className="w-1/2">
                      <DatePickerWithError
                        label="Pedido em"
                        name="orderedAt"
                        date={orderedAt}
                        minDate={getFirstDayOfMonth()}
                        maxDate={new Date()}
                        onChange={(value: any) => onOrderedAtChange(value)}
                      />
                    </div>
                  </div>
                )}

              {(accessPlan?.attributes.usage_type === 'metered' ||
                accessPlan?.attributes.usage_type === 'licensed') && (
                <div className="flex justify-between gap-8">
                  <div className="w-1/2">
                    <InputWithAddon
                      label="Valor"
                      type="text"
                      frontAddon={currency(accessPlan?.attributes.currency)}
                      disabled={accessPlan?.attributes.restricted}
                      name="amount"
                      value={amount}
                      onChange={(value) => onAmountChange(value)}
                    />
                  </div>

                  <div className="w-1/2">
                    <label className="block text-left text-sm font-medium text-gray-700">
                      Diferença
                    </label>
                    <div className="mt-1 h-10 w-full text-left font-medium text-gray-600 border rounded-md py-2 px-2 focus:outline-none bg-gray-50">
                      {accessPlan
                        ? `${
                            accessPlan.attributes.amount !== cleanAmount(amount)
                              ? (accessPlan.attributes.amount as number) >
                                cleanAmount(amount)
                                ? '-'
                                : '+'
                              : ''
                          } ${currency(
                            accessPlan.attributes.currency
                          )} ${formatAmount(
                            Math.abs(
                              (accessPlan.attributes.amount as number) -
                                cleanAmount(amount)
                            )
                          )}`
                        : ''}
                    </div>
                  </div>
                </div>
              )}

              <div className="w-1/3">
                <InputWithAddon
                  disabled={installments.length > 0}
                  label="Taxa de adesão"
                  name="setup_fee"
                  type="text"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  value={subscription?.attributes.setup_fee}
                  frontAddon={currency(accessPlan?.attributes.currency)}
                  onChange={(value) => onAttributeChange('setup_fee', value)}
                />
              </div>

              <div className="flex flex-row space-x-8">
                <div className="w-1/3">
                  <SimpleCustomSelect
                    label="Tipo de multa"
                    options={fineModes}
                    value={subscription?.attributes.fine_mode ?? ''}
                    onChange={(fineModes) =>
                      onAttributeChange('fine_mode', fineModes)
                    }
                  />
                </div>
                <div className="w-1/3">
                  <InputWithAddon
                    label="Valor da multa (por unidade)"
                    name="fine_amount"
                    type="text"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    value={subscription?.attributes.fine_amount}
                    frontAddon={
                      subscription?.attributes.fine_mode === 'fixed'
                        ? currency(accessPlan?.attributes.currency)
                        : '%'
                    }
                    onChange={(value) =>
                      onAttributeChange('fine_amount', value)
                    }
                    error={errors.amount}
                  />
                </div>
              </div>

              <div className="flex flex-row space-x-8">
                <div className="w-1/3">
                  <InputWithAddon
                    label="Valor mínimo da fatura"
                    name="minimum_invoice_amount"
                    type="text"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    value={subscription?.attributes.minimum_invoice_amount}
                    frontAddon={currency(accessPlan?.attributes.currency)}
                    onChange={(value) =>
                      onAttributeChange('minimum_invoice_amount', value)
                    }
                    error={errors.minimum_invoice_amount}
                  />
                </div>
              </div>
            </FormSection>

            <FormSection title="Cupom">
              <CouponSearch
                clear={shouldClear}
                setClear={setShouldClear}
                onSelect={onCouponSelection}
                value={coupon}
                error={errors.coupon}
                plan={accessPlan}
              />
            </FormSection>

            {accessPlan &&
              accessPlan.attributes.usage_type !== UsageType.licensed && (
                <div className="mt-5 w-1/4">
                  <DatePickerWithError
                    label="Pedido em"
                    name="orderedAt"
                    date={orderedAt}
                    minDate={getFirstDayOfMonth()}
                    maxDate={new Date()}
                    onChange={(value: any) => onOrderedAtChange(value)}
                  />
                </div>
              )}

            <div className="mt-4 flex space-x-4 justify-end">
              <ProtectedSection
                roles={[Role.MANAGER, Role.SALES, Role.FINANCE]}
              >
                <DestroyButton id={subscriptionId} />
              </ProtectedSection>

              <Close linkTo="/subscriptions" />
              <Button type="submit" label="Salvar" disabled={isSaving} />
            </div>
          </form>
        </div>
      )}
    </>
  )
}
