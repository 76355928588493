import { checkRoles, isNil, omitBy, cleanAmount } from 'utils'
import { Plan, Role } from 'models'

import { patch, edit as baseEdit } from 'services/billing'
import { ReadResult } from 'interfaces/queryOptions'

export async function toggle(id: number): Promise<ReadResult<Plan>> {
  await checkRoles([Role.MANAGER, Role.FINANCE])

  const { data } = await patch(`/access-plans/${id}/toggle`)

  return data
}

export async function edit(
  id: number,
  attributes: any
): Promise<ReadResult<Plan>> {
  await checkRoles([Role.MANAGER, Role.FINANCE])

  attributes.amount = cleanAmount(attributes.amount)

  const data = {
    attributes: {
      ...omitBy(attributes, isNil)
    }
  }

  if (attributes.max_installments) {
    delete data.attributes.max_installments

    data.attributes.meta = {
      installments: [
        {
          type: attributes.recurring ? 'setup_fee' : 'software',
          requirements: {
            max_installment_number: Number(attributes.max_installments)
          }
        }
      ]
    }
  } else {
    data.attributes.meta = null
  }

  const response = await baseEdit<Plan>('/access-plans', id, data)
  return response
}
