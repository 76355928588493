import { toast } from 'react-toastify'
import { useHistory } from 'react-router'
import React, { useState, useCallback, useEffect } from 'react'
import { TrashIcon } from '@heroicons/react/solid'

import { FormSection } from 'components/Form'
import { PageHeader } from 'components/PageHeader'
import { ClientSearch, PlanSearch } from '../components/Searchs'
import { DatePickerWithError } from 'components/FormInputs/DatePicker'
import {
  InputWithAddon,
  InputWithValidationError,
  RadioListWithDescription,
  SimpleCustomSelect
} from 'components/FormInputs'
import { fineModes } from 'components/FormInputs/options'
import { cleanAmount, formatCurrency } from 'utils'

import { Errors } from 'types'
import { check } from 'utils/error'
import { amount as formatAmount, currency } from 'utils'
import { validateCreation as validate } from '../validation'
import { Cart, create } from '../services'
import { Client, Installment, Order, Plan } from 'models'
import { translations } from '../translations'
import { CreateOrderAttributes } from '../types'
import { Button, Close } from 'components/Buttons'
import { getCurrentDateParts, getFirstDayOfMonth } from 'utils/datetime'
import { ReadResult } from 'interfaces/queryOptions'
import { shippingMethods } from '../helpers/constants'
import { CouponSearch } from '../components/Searchs/Coupon'
import { Coupon, CouponType } from 'models/coupon'
import { CreateInstallment } from '../components/Installments'
import { format } from 'date-fns'
import { list as listInvoices } from 'modules/Invoices/services/read'

function calculateSubtotal(cart: Cart[]) {
  let subtotal = 0
  cart.map((item) => {
    subtotal += (item.order.amount as number) * (item.order.quantity ?? 1)
    return subtotal
  })

  return (subtotal / 100).toFixed(2).replace('.', ',')
}

export function Create() {
  const history = useHistory()

  const [cart, setCart] = useState<Cart[]>([])
  const [orderedAt, setOrderedAt] = useState(new Date())
  const [amount, setAmount] = useState('0,00')

  const [plan, setPlan] = useState<ReadResult<Plan>>()
  const [order, setOrder] = useState<Partial<Order>>({})

  const [client, setClient] = useState<ReadResult<Client>>()
  const [isClientInvoiceInDraftStatus, setIsClientInvoiceInDraftStatus] =
    useState<boolean>(false)

  const [coupon, setCoupon] = useState<Partial<ReadResult<Coupon>>>()

  const [isSaving, setIsSaving] = useState(false)
  const [shouldClear, setShouldClear] = useState(false)
  const [errors, setErrors] = useState<Errors>({})

  const [installments, setInstallments] = useState<Installment[]>([])

  const onAddToCart = useCallback(() => {
    if (!client || !plan) return

    const transformAttributes = (
      attributes: Record<string, any>
    ): Record<string, any> => {
      const { fine_mode, fine_amount } = attributes

      return {
        fine_mode: plan.attributes.recurring
          ? fine_amount === ''
            ? 'fixed'
            : fine_mode
          : null,
        fine_amount: plan.attributes.recurring
          ? fine_amount === ''
            ? 0
            : fine_mode === null
            ? 0
            : cleanAmount(fine_amount)
          : null
      }
    }

    if (check(errors)) return

    order.quantity = order.quantity ?? 1
    order.amount = Math.round(+amount.replace(',', '.') * 100)
    order.ordered_at = orderedAt
    order.shipping_method = order.shipping_method ?? 'shipping'

    const otherAttributes = transformAttributes(order)

    const orderInstallments = order.amount !== 0 ? installments : []

    if (coupon) {
      const couponApplied = {
        id: coupon.id,
        type: 'coupon' as CouponType
      }
      cart.push({
        order: { ...order, ...otherAttributes },
        plan,
        couponApplied,
        orderInstallments
      })
    } else {
      cart.push({
        order: { ...order, ...otherAttributes },
        plan,
        orderInstallments
      })
    }

    setCart([...cart])

    setInstallments([])

    setShouldClear(true)
    setOrder({})
    setAmount('0,00')
    setPlan(undefined)
    setErrors({})
  }, [
    cart,
    client,
    amount,
    errors,
    order,
    orderedAt,
    plan,
    coupon,
    installments
  ])

  const onRemoveFrontCart = useCallback(
    (index: number) => {
      cart.splice(index, 1)
      setCart([...cart])
      setCoupon({})
    },
    [cart]
  )

  const onCheckout = useCallback(async () => {
    if (!client || !cart.length) return

    setIsSaving(true)
    try {
      await create(cart, client)

      toast.success('Pedido(s) criado(s) com sucesso')
      setIsSaving(false)
      history.push('/orders')
    } catch (err: any) {
      setIsSaving(false)
      setInstallments([])
      toast.error(
        err.suggestedMessage ??
          'Falha ao tentar salvar os dados do(s) pedido(s)'
      )
    }
  }, [cart, client, history])

  const onAttributeChange = useCallback(
    (attr: keyof CreateOrderAttributes, value: any): void => {
      const error = validate(attr as any, value)

      if (attr === 'fine_amount') {
        value = formatCurrency(value)
      }

      setOrder({ ...order, [attr]: value })
      setErrors({ ...errors, [attr]: error })
    },
    [errors, order]
  )

  const onOrderedAtChange = useCallback((value: string) => {
    setOrderedAt(new Date(value))
  }, [])

  const onAmountChange = useCallback(
    (value: string) => {
      const newAmount = formatAmount(value.replace(/[^0-9]/g, ''))

      if (value === '-0,00' || value === '-0,0') {
        setAmount('0,00')
      } else {
        value.includes('-') ? setAmount('-' + newAmount) : setAmount(newAmount)
      }
    },
    [plan]
  )

  useEffect(() => {
    if (plan?.attributes) {
      const { fine_mode, fine_amount, amount } = plan.attributes

      setOrder({
        ...order,
        fine_mode: fine_mode ?? 'fixed',
        fine_amount: formatAmount(fine_amount)
      })

      setAmount(formatAmount(amount))
    }
  }, [plan])

  const onCouponSelection = useCallback((coupon: any): void => {
    setCoupon(coupon ?? {})
  }, [])

  useEffect(() => {
    const fetchInvoices = async () => {
      if (client) {
        try {
          const billingCycleDay = client.attributes.billing_cycle_day

          const { year, month } = getCurrentDateParts()

          const dueDate = new Date(year, month, billingCycleDay)
          const formattedDate = format(dueDate, 'yyyy-MM-dd')

          const invoices = await listInvoices({
            includes: { statuses: ['status'] },
            filters: [
              { key: 'client_id', op: 'eq', value: client.id },
              { key: 'due_at', op: 'eq', value: formattedDate }
            ]
          })

          setIsClientInvoiceInDraftStatus(
            invoices.data.some(
              (inv) =>
                inv.relationships?.statuses[0].attributes.status === 'draft'
            )
          )
        } catch (error) {
          console.error('Error fetching invoices:', error)
        }
      }
    }

    fetchInvoices()
  }, [client])

  return (
    <>
      <PageHeader title="Pedidos" action="Novos" />

      <div className="flex flex-row space-x-10 px-4 animate-fade-in-down">
        <div className="w-1/2">
          <form
            onSubmit={(e) => {
              e.preventDefault()
              onCheckout()
            }}
          >
            <FormSection title="Cliente">
              <ClientSearch
                disabled={cart.length > 0}
                onSelect={setClient}
                error={errors.client}
                planCountryId={plan?.relationships?.country.id}
              />
            </FormSection>

            <FormSection title="Plano">
              <PlanSearch
                clear={shouldClear}
                setClear={setShouldClear}
                onSelect={setPlan}
                onChange={setPlan}
                error={errors.plan}
                clientCountryId={client?.relationships?.country.id}
              />
            </FormSection>

            <FormSection title="Pedido">
              <div className="flex justify-between gap-8">
                <div className="w-1/2">
                  <InputWithValidationError
                    label="Quantidade"
                    type="number"
                    name="quantity"
                    min="1"
                    value={order?.quantity ?? 1}
                    onChange={(value) => onAttributeChange('quantity', value)}
                    error={errors.quantity}
                    onWheel={(e: React.FocusEvent<HTMLInputElement>) =>
                      e.target.blur()
                    }
                  />
                </div>

                <div className="w-1/2">
                  <DatePickerWithError
                    label="Pedido em"
                    name="orderedAt"
                    date={orderedAt}
                    minDate={getFirstDayOfMonth()}
                    maxDate={new Date()}
                    onChange={(value: any) => onOrderedAtChange(value)}
                  />
                </div>
              </div>

              <div className="flex justify-between gap-8">
                <div className="w-1/2">
                  <InputWithAddon
                    label="Valor"
                    type="text"
                    frontAddon={currency(plan?.attributes.currency)}
                    disabled={plan?.attributes.restricted}
                    name="amount"
                    value={amount}
                    onChange={(value) => onAmountChange(value)}
                  />
                </div>

                <div className="w-1/2">
                  <label className="block text-left text-sm font-medium text-gray-700">
                    Diferença
                  </label>
                  <div className="mt-1 h-10 w-full text-left font-medium text-gray-600 border rounded-md py-2 px-2 focus:outline-none bg-gray-50">
                    {plan
                      ? `${
                          plan.attributes.amount !== cleanAmount(amount)
                            ? (plan.attributes.amount as number) >
                              cleanAmount(amount)
                              ? '-'
                              : '+'
                            : ''
                        } ${currency(plan.attributes.currency)} ${formatAmount(
                          Math.abs(
                            (plan.attributes.amount as number) -
                              cleanAmount(amount)
                          )
                        )}`
                      : ''}
                  </div>
                </div>
              </div>

              {plan &&
                client &&
                plan?.attributes.recurring === false &&
                plan.attributes.meta?.installments?.find(
                  (installment: any) =>
                    installment.type === plan.attributes.type
                ) &&
                cleanAmount(amount) !== 0 && (
                  <CreateInstallment
                    type="non_recurring"
                    totalAmount={(() => {
                      const baseAmount = cleanAmount(amount)
                      const quantity = order?.quantity ?? 1
                      return baseAmount * quantity
                    })()}
                    amountCurrency={plan.attributes.currency as string}
                    billingCycleDay={
                      client?.attributes.billing_cycle_day as number
                    }
                    setInstallments={setInstallments}
                    maxNumberOfInstallments={
                      plan.attributes.meta?.installments.find(
                        (installment: any) =>
                          installment.type === plan.attributes.type
                      ).requirements.max_installment_number
                    }
                    clientInvoiceInDraftStatus={isClientInvoiceInDraftStatus}
                    couponApplied={coupon ? true : false}
                  />
                )}

              {plan && plan.attributes.recurring && (
                <div className="flex flex-row space-x-8">
                  <div className="w-1/3">
                    <SimpleCustomSelect
                      label="Tipo de multa"
                      options={fineModes}
                      value={order?.fine_mode ?? ''}
                      onChange={(fineModes) =>
                        onAttributeChange('fine_mode', fineModes)
                      }
                    />
                  </div>

                  <div className="w-1/3">
                    <InputWithAddon
                      label="Valor da multa (por unidade)"
                      name="fine_amount"
                      type="text"
                      pattern="[0-9]+([\.,][0-9]+)?"
                      value={order?.fine_amount}
                      frontAddon={
                        order.fine_mode === 'fixed'
                          ? currency(plan?.attributes.currency)
                          : '%'
                      }
                      onChange={(value) =>
                        onAttributeChange('fine_amount', value)
                      }
                      error={errors.amount}
                    />
                  </div>
                </div>
              )}

              {plan && plan.attributes.recurring && (
                <FormSection title="Cupom">
                  <CouponSearch
                    clear={shouldClear}
                    setClear={setShouldClear}
                    onSelect={onCouponSelection}
                    error={errors.coupon}
                    plan={plan}
                    isInstallmentPurchase={!plan.attributes.recurring}
                  />
                </FormSection>
              )}

              <RadioListWithDescription
                label="Método de envio"
                options={shippingMethods}
                value={order?.shipping_method ?? 'shipping'}
                onChange={(value) =>
                  onAttributeChange('shipping_method', value)
                }
              />
            </FormSection>

            <div className="mt-4 flex justify-end items-center space-x-4">
              <Close linkTo="/orders" />
              <Button type="button" label="Adicionar" onClick={onAddToCart} />
            </div>
          </form>
        </div>

        {cart.length > 0 ? (
          <div className="w-5/12 relative animate-fade-in-down">
            <div className="flex px-5 w-full sticky top-0">
              <div className="h-full flex flex-col bg-white shadow-xl rounded-md border-md w-full mt-5">
                <div className="flex-1 py-6 px-4 ">
                  <div className="flex items-start justify-between">
                    <h3 className="text-xl text-left leading-6 font-medium text-gray-900">
                      Carrinho
                    </h3>
                    <div className="ml-3 h-7 flex items-center">
                      <button
                        type="button"
                        className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                        onClick={() => setCart([])}
                      >
                        <span className="sr-only">Esvaziar</span>
                        <TrashIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>

                  <div className="mt-8">
                    <div className="flow-root">
                      <ul className="-my-6 divide-y divide-gray-200">
                        {cart.map((item, index) => (
                          <li key={index} className="py-4 flex">
                            <div className="ml-4 flex-1 flex flex-col">
                              <div>
                                <div className="mb-3 flex justify-between text-base font-medium text-gray-900">
                                  <h3>
                                    <p className="text-left whitespace-nowrap overflow-ellipsis overflow-hidden">
                                      {item.plan.attributes.name}
                                    </p>
                                  </h3>
                                  <div className="flex-col">
                                    <p className="ml-4 whitespace-nowrap overflow-ellipsis overflow-hidden">
                                      {currency(item.plan.attributes.currency)}{' '}
                                      {formatAmount(item.order.amount)}
                                    </p>
                                  </div>
                                </div>
                                <p className="text-left text-sm text-gray-500">
                                  {
                                    translations.plan_type[
                                      item.plan.type as string
                                    ]
                                  }
                                </p>
                              </div>
                              <div className="flex-1 flex items-end justify-between text-sm">
                                <p className="text-gray-500">
                                  Quantidade: {item.order.quantity}
                                </p>

                                <div className="flex">
                                  <button
                                    className="font-medium text-emerald-600 hover:text-red-500"
                                    onClick={() => onRemoveFrontCart(index)}
                                  >
                                    Remover
                                  </button>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="border-t border-gray-200 py-6 px-4">
                  <div className="mb-3 flex justify-between text-base font-medium text-gray-900">
                    <p>Subtotal</p>
                    <p>
                      {cart.length
                        ? `${currency(
                            cart[0].plan.attributes.currency
                          )} ${calculateSubtotal(cart)}`
                        : ''}
                    </p>
                  </div>
                  <p className="mt-0.5 text-sm text-gray-500">
                    Frete e taxas serão calculados no checkout.
                  </p>
                  <div className="mt-6">
                    <button
                      type="button"
                      disabled={isSaving}
                      className={`inline w-full px-5 py-3 border border-transparent rounded-md shadow-sm text-base font-medium ${
                        isSaving
                          ? ' bg-gray-300'
                          : 'text-white bg-emerald-600 hover:bg-emerald-700'
                      }`}
                      onClick={() => onCheckout()}
                    >
                      {isSaving ? (
                        <div className="flex justify-center items-center">
                          <svg
                            className="animate-spin rounded-full h-5 w-5"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <circle
                              className="opacity-25 stroke-current text-gray-700"
                              cx="12"
                              cy="12"
                              r="10"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75 fill-current text-gray-700"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        </div>
                      ) : (
                        'Checkout'
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  )
}
