import Downshift from 'downshift'
import debounce from 'lodash.debounce'
import { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid'

import config from 'config'
import { amount, currency } from 'utils'
import { list as listCoupons } from 'modules/Coupons/services/read'
import { translations } from 'modules/Orders/translations'
import { ListResult, ReadResult } from 'interfaces/queryOptions'
import { Coupon } from 'models/coupon'
import { toast } from 'react-toastify'

interface Props {
  clear: boolean
  onSelect: (value: any) => any
  value?: ReadResult<Coupon>
  isInstallmentPurchase?: boolean
  [key: string]: any
}

export function CouponSearch({
  onSelect,
  error,
  clear,
  setClear,
  value,
  plan,
  isInstallmentPurchase = false
}: Props) {
  const [isLoading, setIsLoading] = useState(false)
  const [search, setSearch] = useState('')

  const [coupon, setCoupon] = useState<ReadResult<Coupon>>()
  const [coupons, setCoupons] = useState<ListResult<Coupon>>()
  const [errors, setErrors] = useState(error)

  const isInitialRender = useRef(true)

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
      return
    }

    if (search === '') {
      setCoupon(undefined)
      setCoupons(undefined)
      onSelect(undefined)
      setSearch('')
    }
  }, [search])

  const onSearch = useMemo(
    () =>
      debounce(async (search: string) => {
        try {
          if (search.length < config.min_search_length) return
          setIsLoading(true)

          const baseFilters = [{ key: 'deactivated_at', op: 'is', value: null }]

          const planFilters = [
            {
              key: 'country_id',
              op: 'eq',
              value: plan.relationships.country.id
            },
            { key: 'type', op: 'eq', value: plan.attributes.type },
            { key: 'currency', op: 'eq', value: plan.attributes.currency },
            ...(isInstallmentPurchase
              ? []
              : [
                  { key: 'interval', op: 'eq', value: plan.attributes.interval }
                ])
          ]

          const installmentPurchaseFilters = [
            { key: 'duration_mode', op: 'eq', value: 'once' }
          ]

          const filters = [
            ...baseFilters,
            ...planFilters,
            ...(isInstallmentPurchase ? installmentPurchaseFilters : [])
          ]

          const queryOptions = {
            attributes: ['*'],
            filters,
            search
          }

          const result = await listCoupons(queryOptions)

          setCoupons(filterCoupons(result))
          setIsLoading(false)
        } catch (err: any) {
          setIsLoading(false)
          console.error(err)
        }
      }, config.autocomplete_debounce_rate),
    [plan]
  )

  const filterCoupons = (result: ListResult<Coupon>): ListResult<Coupon> => {
    const now = new Date()

    const filteredData = result.data.filter((coupon) => {
      const expiresAt = coupon.attributes.expires_at
        ? new Date(coupon.attributes.expires_at)
        : null
      return (
        !(expiresAt && expiresAt <= now) &&
        !(
          coupon.attributes.redeems_remaining != null &&
          (coupon.attributes.redeems_remaining as number) <= 0
        )
      )
    })

    return {
      count: filteredData.length,
      data: filteredData
    }
  }

  useEffect(() => {
    if (!value) return

    setCoupon(value)
    setSearch(value.attributes.name ?? '')
  }, [value])

  useEffect(() => {
    if (search === '') return

    if (search === coupon?.attributes.name) return

    setErrors('')

    onSearch(search)
  }, [coupon, onSearch, search])

  const onClick = useCallback(
    (index: number) => {
      if (
        !coupons ||
        !coupons.data ||
        !coupons.data[index] ||
        !coupons.data[index].attributes
      )
        return

      const { redeems_remaining, expires_at } = coupons.data[index].attributes

      if (redeems_remaining != null && (redeems_remaining as number) <= 0) {
        toast.error('Cupom já atingiu o limite máximo de resgates permitidos')
        return
      }

      if (expires_at != null && new Date() > expires_at) {
        toast.error('Cupom expirado')
        return
      }

      setCoupon(coupons.data[index])
      setSearch(coupons.data[index].attributes.name ?? '')
      onSelect(coupons.data[index])
    },
    [coupons, onSelect]
  )

  return (
    <div>
      <Downshift
        onChange={(selection) => (selection ? onSelect(coupon) : undefined)}
        itemToString={(item) => item?.attributes.name ?? ''}
        selectedItem={coupon ?? { attributes: { name: '' } }}
      >
        {({ getInputProps, getItemProps, isOpen }) => (
          <div className="relative">
            <label className="block text-left text-sm font-medium text-gray-700">
              Nome
            </label>
            <div className="relative flex items-center">
              <input
                className={
                  'mt-1 block w-full rounded-md shadow-sm py-2 px-3 border focus:outline-none ' +
                  (error
                    ? 'border-red-500 focus:border-red-500'
                    : 'border-gray-300 focus:border-emerald-500')
                }
                {...getInputProps({
                  value: clear ? '' : search || '',
                  onChange: (e) => {
                    setSearch(e.currentTarget.value)
                  },
                  onFocus: clear
                    ? () => {
                        setCoupon(undefined)
                        setSearch('')
                        setClear(false)
                      }
                    : undefined
                })}
              />
              {isLoading && (
                <div className="absolute right-1">
                  <svg
                    className="animate-spin mt-1 mr-3 h-5 w-5 text-emerald-700"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
              )}
              {error && (
                <div className="absolute inset-y-0 right-0 pt-1 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon
                    className="h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                </div>
              )}
            </div>
            {errors && (
              <p className="absolute left-0 text-xs text-red-500">{errors}</p>
            )}
            <ul className="w-full absolute mt-2 rounded-md bg-white z-20 shadow flex flex-col">
              {isOpen && coupons && coupons?.data.length > 0 && (
                <div className="max-h-96 overflow-auto">
                  {coupons.data.map((item, index) => (
                    <div
                      className="px-4 py-2 w-full cursor-pointer bg-white hover:bg-gray-100 flex justify-between"
                      key={item.id}
                      {...getItemProps({
                        key: item.id,
                        onClick: () => {
                          onClick(index)
                        },
                        item
                      })}
                    >
                      <div>
                        <p className="text-md text-left text-gray-900">
                          {item.attributes.name}
                        </p>
                        <p className="text-sm text-left text-gray-700">
                          {item.attributes.mode === 'fixed'
                            ? `${currency(item.attributes.currency)} ${amount(
                                item.attributes.amount
                              )}`
                            : `${amount(item.attributes.amount)}%`}
                          {' - '}
                          {item.attributes.duration_mode === 'repeating'
                            ? `${item.attributes.duration} ${
                                translations.coupon_interval[
                                  item.attributes.interval as string
                                ]
                              }`
                            : `Aplicação ${
                                translations.duration_mode[
                                  item.attributes.duration_mode as string
                                ]
                              }`}
                        </p>
                      </div>
                      <div id="device-suport">
                        <CheckCircleIcon className="w-6 h-6 mt-2 mr-2 text-emerald-500" />
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {isOpen && !coupons?.data.length && (
                <li className="px-4 py-2 w-full cursor-pointer bg-white hover:bg-gray-100 flex justify-start">
                  Nenhum resultado encontrado
                </li>
              )}
            </ul>

            <div className="flex pt-4 justify-between gap-8">
              <div className="w-1/2">
                <label className="block text-left text-sm font-medium text-gray-700">
                  Aplicação
                </label>
                <div className="mt-1 h-10 w-full text-left font-medium text-gray-600 border rounded-md py-2 px-2 focus:outline-none bg-gray-50">
                  {coupon && coupon.id && !clear
                    ? translations.duration_mode[
                        coupon.attributes.duration_mode as string
                      ]
                    : ''}
                </div>
              </div>

              <div className="w-1/2">
                <label className="block text-left text-sm font-medium text-gray-700">
                  Tipo
                </label>
                <div className="mt-1 h-10 w-full text-left font-medium text-gray-600 border rounded-md py-2 px-2 focus:outline-none bg-gray-50">
                  {coupon && coupon.id && !clear
                    ? translations.plan_type[plan.attributes.type as string]
                    : ''}
                </div>
              </div>
            </div>

            <div className="flex pt-4 justify-between gap-8">
              <div className="w-1/3">
                <label className="block text-left text-sm font-medium text-gray-700">
                  Valor
                </label>
                <div className="mt-1 h-10 w-full text-left font-medium text-gray-600 border rounded-md py-2 px-2 focus:outline-none bg-gray-50">
                  {coupon && coupon.id && !clear
                    ? coupon.attributes.mode === 'fixed'
                      ? `${currency(coupon.attributes.currency)} ${amount(
                          coupon.attributes.amount
                        )}`
                      : `${amount(coupon.attributes.amount)}%`
                    : ''}
                </div>
              </div>

              <div className="w-1/3">
                <label className="block text-left text-sm font-medium text-gray-700">
                  Duração
                </label>
                <div className="mt-1 h-10 w-full text-left font-medium text-gray-600 border rounded-md py-2 px-2 focus:outline-none bg-gray-50">
                  {coupon &&
                  coupon.id &&
                  !clear &&
                  coupon.attributes.duration_mode === 'repeating'
                    ? `${coupon.attributes.duration} ${
                        translations.coupon_interval[
                          coupon.attributes.interval as string
                        ]
                      }`
                    : '-'}
                </div>
              </div>

              <div className="w-1/3">
                <label className="block text-left text-sm font-medium text-gray-700">
                  Intervalo de cobrança
                </label>
                <div className="mt-1 h-10 w-full text-left font-medium text-gray-600 border rounded-md py-2 px-2 focus:outline-none bg-gray-50">
                  {coupon && coupon.id && !clear
                    ? translations.plan_interval[
                        coupon.attributes.interval as string
                      ]
                    : ''}
                </div>
              </div>
            </div>
          </div>
        )}
      </Downshift>
    </div>
  )
}
