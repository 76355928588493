import { Plan, UsageType, Role } from 'models'

import { create as baseCreate } from 'services/billing'

import { checkRoles, isNil, omitBy, cleanAmount } from 'utils'

import { AccessPlanForm } from '../types'

export async function create(formData: AccessPlanForm, countryId: string) {
  await checkRoles([Role.MANAGER, Role.SALES])

  const { usage_type } = formData

  if (!usage_type) return

  const data: Record<string, any> = payloadBuilder[usage_type](formData)

  if (formData.max_installments) {
    delete data.attributes.max_installments

    data.attributes.meta = {
      installments: [
        {
          type: formData.recurring ? 'setup_fee' : 'software',
          requirements: {
            max_installment_number: Number(formData.max_installments)
          }
        }
      ]
    }
  }

  data.relationships = {
    country: {
      type: 'country',
      id: countryId
    }
  }

  const response = await baseCreate<Plan>('/access-plans', data)
  return response
}

const payloadBuilder = {
  [UsageType.metered]: (formData: AccessPlanForm) => {
    const attributes = {
      ...formData,
      amount: cleanAmount(formData.amount),
      tiers: undefined,
      tier_mode: undefined
    }

    return {
      attributes: {
        ...omitBy(attributes, isNil)
      }
    }
  },
  [UsageType.licensed]: (formData: AccessPlanForm) => {
    const attributes = {
      ...formData,
      amount: cleanAmount(formData.amount),
      tiers: undefined,
      tier_mode: undefined
    }

    return { attributes: omitBy(attributes, isNil) }
  },
  [UsageType.tiered]: (formData: AccessPlanForm) => {
    const attributes = {
      ...formData,
      amount: undefined
    }

    return { attributes: omitBy(attributes, isNil) }
  }
}
