import { toast } from 'react-toastify'
import { useParams, useHistory } from 'react-router'
import { useCallback, useEffect, useState } from 'react'

import { FormSection } from 'components/Form'
import { DestroyButton } from '../components/Destroy'
import { PageHeader } from 'components/PageHeader'
import { Close, Button } from 'components/Buttons'
import { LoadingCircle } from 'components/Loading'
import { ProtectedSection } from 'components/ProtectedSection'
import { ClientSearch, PlanSearch } from '../components/Searchs'
import { DatePickerWithError } from 'components/FormInputs/DatePicker'
import {
  InputWithAddon,
  InputWithValidationError,
  RadioListWithDescription,
  SimpleCustomSelect
} from 'components/FormInputs'

import { Errors } from 'types'
import { check } from 'utils/error'
import { validateUpdate as validate } from '../validation'
import { edit, newShow } from '../services'
import {
  amount as formatAmount,
  currency,
  formatCurrency,
  cleanAmount
} from 'utils'
import { Client, Order, Plan, Role } from 'models'
import { isCurrentMonth } from '../helpers/utils'
import { getFirstDayOfMonth } from 'utils/datetime'
import { ReadResult } from 'interfaces/queryOptions'
import { shippingMethods } from '../helpers/constants'
import { fineModes } from 'components/FormInputs/options'
import { Coupon } from 'models/coupon'
import { CouponSearch } from '../components/Searchs/Coupon'

export function Edit() {
  const { orderId } = useParams() as unknown as { orderId: number }

  const history = useHistory()

  const [order, setOrder] = useState<ReadResult<Order>>()
  const [client, setClient] = useState<ReadResult<Client>>()
  const [plan, setPlan] = useState<ReadResult<Plan>>()

  const [coupon, setCoupon] = useState<ReadResult<Coupon>>()

  const [shouldClear, setShouldClear] = useState(false)

  const [orderedAt, setOrderedAt] = useState(new Date())
  const [amount, setAmount] = useState('0,00')

  const [errors, setErrors] = useState<Errors>({})

  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const [installments, setInstallments] = useState([])

  useEffect(() => {
    const fetch = async () => {
      if (!orderId) return

      setIsLoading(true)
      try {
        const options = {
          includes: {
            client: ['id', 'account_name', 'document', 'document_type'],
            plan: [
              'id',
              'name',
              'type',
              'nature',
              'amount',
              'currency',
              'duration',
              'interval',
              'restricted',
              'country_id',
              'recurring'
            ],
            coupon: ['*'],
            order_installments: ['*']
          }
        }
        const result = await newShow(orderId, options)

        setOrder({
          ...result,
          attributes: {
            ...result.attributes,
            ...(result.attributes.fine_amount && {
              fine_amount: formatAmount(result.attributes.fine_amount)
            })
          }
        })

        if (result.relationships?.order_installments) {
          setInstallments(
            result.relationships.order_installments.map(
              ({ attributes }: any) => ({
                type: attributes.type,
                amount: attributes.amount,
                due_at: attributes.due_at,
                upfront: attributes.upfront,
                eligible: attributes.eligible
              })
            )
          )
        }

        setAmount(formatAmount(result.attributes.amount))
        setOrderedAt(new Date(result.attributes.ordered_at ?? new Date()))
        setClient(result.relationships?.client)
        setPlan({
          ...result.relationships?.plan,
          relationships: {
            country: { id: result.relationships?.plan.attributes.country_id }
          }
        })

        setCoupon(result.relationships?.coupon ?? null)

        setIsLoading(false)
      } catch (err: any) {
        console.error(err)
        history.push('/orders')
        toast.error(
          err.suggestedMessage ?? 'Falha ao buscar os dados do pedido'
        )
        setIsLoading(false)
      }
    }
    fetch()
  }, [orderId])

  const onSave = useCallback(async () => {
    if (!order || !plan || !client) return

    if (check(errors)) return

    if (installments.length > 0) {
      toast.warning('Não é possível editar um pedido parcelado')
      return
    }

    order.attributes.ordered_at = isCurrentMonth(orderedAt)
      ? orderedAt
      : undefined
    order.attributes.amount = Math.round(+amount.replace(',', '.') * 100)
    if (!order.attributes.fine_amount) {
      order.attributes.fine_amount = 0
    }

    if (!plan.attributes.recurring) {
      order.attributes.fine_mode = 'fixed'
      order.attributes.fine_amount = 0
    }

    setIsSaving(true)
    try {
      await edit(orderId, { order, client, plan, coupon })
      toast.success('Pedido editado com sucesso')
      setIsSaving(false)
    } catch (err: any) {
      setIsSaving(false)
      toast.error(
        err.suggestedMessage ?? 'Houve uma falha ao salvar as alterações'
      )
    }
  }, [client, amount, errors, orderId, order, orderedAt, plan, coupon])

  const onAttributeChange = useCallback(
    (attr: keyof Order, value: any): void => {
      if (!order) return

      const error = validate(attr as any, value)

      if (attr === 'fine_amount' && value === '') {
        setOrder({
          ...order,
          attributes: {
            ...order.attributes,
            fine_amount: '',
            fine_mode: null as unknown as string
          }
        })
        return
      }

      if (attr === 'fine_amount') {
        value = formatCurrency(value)
      }

      setOrder({
        ...order,
        attributes: { ...order.attributes, [attr]: value }
      })
      setErrors({ ...errors, [attr]: error })
    },
    [errors, order]
  )

  const onAmountChange = useCallback((value: string) => {
    if (value === '-0,00' || value === '-0,0') {
      setAmount('0,00')
    } else {
      const newAmount = formatAmount(value.replace(/[^0-9]/g, ''))
      value.includes('-') ? setAmount('-' + newAmount) : setAmount(newAmount)
    }
  }, [])

  const onOrderedAtChange = useCallback((value: string) => {
    setOrderedAt(new Date(value))
  }, [])

  const onCouponSelection = useCallback((coupon: any): void => {
    setCoupon(coupon)
  }, [])

  return (
    <>
      <PageHeader title="Pedidos" action="Edição" />

      {isLoading ? (
        <LoadingCircle />
      ) : (
        <div className="w-3/5 px-4 animate-fade-in-down">
          <h3 className="text-xl mb-5 leading-6 font-medium text-gray-900">
            Pedido: {order?.attributes.token}
          </h3>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              onSave()
            }}
          >
            <FormSection title="Cliente">
              <ClientSearch
                value={client}
                onSelect={setClient}
                error={errors?.client_id}
              />
            </FormSection>

            <FormSection title="Plano">
              <PlanSearch
                clear={false}
                value={plan}
                onSelect={setPlan}
                onChange={setPlan}
                error={errors?.plan_id}
              />
            </FormSection>

            <FormSection title="Pedido">
              <div className="flex justify-between gap-8">
                <div className="w-1/2">
                  <InputWithValidationError
                    label="Quantidade"
                    type="number"
                    name="quantity"
                    min="1"
                    value={order?.attributes.quantity}
                    onChange={(value) => onAttributeChange('quantity', value)}
                    error={errors?.quantity}
                    onWheel={(e: React.FocusEvent<HTMLInputElement>) =>
                      e.target.blur()
                    }
                  />
                </div>

                <div className="w-1/2">
                  <DatePickerWithError
                    label="Pedido em"
                    name="orderedAt"
                    date={orderedAt}
                    minDate={getFirstDayOfMonth()}
                    maxDate={new Date()}
                    onChange={(value: any) => onOrderedAtChange(value)}
                  />
                </div>
              </div>

              <div className="flex justify-between gap-8">
                <div className="w-1/2">
                  <InputWithAddon
                    label="Valor"
                    type="text"
                    frontAddon={currency(plan?.attributes.currency)}
                    disabled={plan?.attributes.restricted}
                    name="amount"
                    value={amount}
                    onChange={(value) => onAmountChange(value)}
                  />
                </div>

                <div className="w-1/2">
                  <label className="block text-left text-sm font-medium text-gray-700">
                    Diferença
                  </label>
                  <div className="mt-1 h-10 w-full text-left font-medium text-gray-600 border rounded-md py-2 px-2 focus:outline-none bg-gray-50">
                    {plan
                      ? `${
                          plan.attributes.amount !== cleanAmount(amount)
                            ? (plan.attributes.amount as number) >
                              cleanAmount(amount)
                              ? '-'
                              : '+'
                            : ''
                        } ${currency(plan.attributes.currency)} ${formatAmount(
                          Math.abs(
                            (plan.attributes.amount as number) -
                              cleanAmount(amount)
                          )
                        )}`
                      : ''}
                  </div>
                </div>
              </div>

              {plan && plan.attributes.recurring && (
                <div className="flex flex-row space-x-8">
                  <div className="w-1/3">
                    <SimpleCustomSelect
                      label="Tipo de multa"
                      options={fineModes}
                      value={order?.attributes.fine_mode ?? ''}
                      onChange={(fineModes) =>
                        onAttributeChange('fine_mode', fineModes)
                      }
                    />
                  </div>

                  <div className="w-1/3">
                    <InputWithAddon
                      label="Valor da multa (por unidade)"
                      name="fine_amount"
                      type="text"
                      pattern="[0-9]+([\.,][0-9]+)?"
                      value={order?.attributes.fine_amount}
                      frontAddon={
                        order?.attributes.fine_mode === 'fixed'
                          ? currency(plan?.attributes.currency)
                          : '%'
                      }
                      onChange={(value) =>
                        onAttributeChange('fine_amount', value)
                      }
                      error={errors.amount}
                    />
                  </div>
                </div>
              )}

              {plan?.attributes.recurring && (
                <FormSection title="Cupom">
                  <CouponSearch
                    clear={shouldClear}
                    setClear={setShouldClear}
                    onSelect={onCouponSelection}
                    value={coupon}
                    error={errors.coupon}
                    plan={plan}
                  />
                </FormSection>
              )}

              <RadioListWithDescription
                label="Método de envio"
                options={shippingMethods}
                value={order?.attributes.shipping_method}
                onChange={(value) =>
                  onAttributeChange('shipping_method', value)
                }
              />
            </FormSection>

            <div className="mt-4 flex space-x-4 justify-end">
              <ProtectedSection
                roles={[Role.MANAGER, Role.SALES, Role.FINANCE]}
              >
                <DestroyButton id={orderId} />
              </ProtectedSection>

              <Close linkTo="/orders" />
              <Button type="submit" label="Salvar" disabled={isSaving} />
            </div>
          </form>
        </div>
      )}
    </>
  )
}
